<template>
  <div class="container">
    <Header is-back>
      <template v-slot:title>
        <span>{{ $t('offlinePayment') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <div class="block">
          <h2>{{ $t('bookingInformation') }}</h2>
          <van-cell-group>
            <van-field v-model="reservation.booking_number" label-width="100px" :label="$t('bookingNumber')" readonly />
            <van-field class="rate-field" :value="totalAmount" label-width="100px" :label="$t('totalAmount')" readonly />
          </van-cell-group>
        </div>

        <div class="block">
          <h2>{{ $t('offlinePayment2') }}</h2>
          <div style="text-align: center; height: 60px; line-height: 60px; background-color: #F1F1F1;">
            人民币（RMB）
          </div>
          <van-row type="flex">
            <van-col span="7">公司名称</van-col>
            <van-col span="17">{{ company.name }}</van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="7">开户银行</van-col>
            <van-col span="17">{{ company.bank }}</van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="7">银行账号</van-col>
            <van-col span="17">{{ company.account }}</van-col>
          </van-row>

          <div style="text-align: center; height: 60px; line-height: 60px; background-color: #F1F1F1; margin-top: 20px;">
            美元（USD）
          </div>

          <van-row type="flex">
            <van-col span="7">Account Name</van-col>
            <van-col span="17">{{ company2.name }}</van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="7">Bank Name</van-col>
            <van-col span="17">{{ company2.bank }}</van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="7">Account</van-col>
            <van-col span="17">{{ company2.account }}</van-col>
          </van-row>
          <van-row type="flex">
            <van-col span="7">SWIFT Code</van-col>
            <van-col span="17">{{ company2.swiftCode }}</van-col>
          </van-row>
        </div>

        <div class="note">
          {{ paymentNote }}
        </div>

        <div v-if="reservation.payment_status === 'Unpaid'" style="margin-top: 30px; display: flex; flex-direction: row; align-items: center;">
          <span class="cu-count-down">{{ day }}</span>
          <span>{{ $t('day') }}</span>
          <span class="cu-count-down">{{ hour }}</span>
          <span>{{ $t('hour') }}</span>
          <span class="cu-count-down">{{ minute }}</span>
          <span>{{ $t('minute') }}</span>
          <span class="cu-count-down">{{ second }}</span>
          <span style="padding-right: 8px;">{{ $t('second') }}</span>
        </div>

        <div style="padding-top: 10px;">
          <van-uploader
            style="width: 200px;"
            preview-size="200px"
            v-model="fileList"
            :max-count="1"
            :max-size="5000 * 1024"
            :after-read="afterRead"
            accept="image/png,image/jpeg"
            :preview-options="{closeable: true}"
            :disabled="time <= 0"
            @oversize="onOversize"
          />
          <div style="color: #515a6e;">{{ $t('upload file only for JPG/JPEG/PNG , and it is limited to 5MB') }}</div>
        </div>

        <div style="padding-bottom: 10px;">
          <van-field
            v-model="reservation.remark"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            :placeholder="$t('paymentRemark')"
            show-word-limit
            style="border: 1px solid #dedede;"
          />
        </div>
      </div>
    </div>

    <div class="footer">
      <van-button color="#005DA9" style="width: 100%; border-radius: 0;" :disabled="reservation.payment_status !== 'Unpaid' || time <= 0 || fileList.length < 1" @click="submit">{{ $t('submit') }}</van-button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { Notify } from 'vant'
import Compressor from 'compressorjs'

export default {
  name: 'PublicPay',
  components: {
    Header
  },
  data () {
    return {
      reservation: {
        booking_number: '',
        total_amount: '',
        last_pay_time: '',
        payment_method: '',
        payment_status: '',
        currency_code: '',
        remark: ''
      },
      day: '-',
      hour: '-',
      minute: '-',
      second: '-',
      paymentNote: '',
      fileList: [],
      company: {
        name: '北京东方美景展览有限公司',
        bank: '中国农业银行股份有限公司北京惠新里支行',
        account: '11191101040016896',
        swiftCode: 'ABOCCNBJ010'
      },
      company2: {
        name: 'Beijing Oriental Magic Exhibition Co., Ltd',
        bank: 'Agricultural Bank of China Beijing Branch',
        account: '11191101040016896',
        swiftCode: 'ABOCCNBJ010'
      }
    }
  },
  computed: {
    time () {
      return dayjs(this.reservation.last_pay_time).diff(dayjs())
    },
    totalAmount () {
      if (this.reservation && this.reservation.currency_code) {
        return this.reservation.currency_code + formatNumber(this.reservation.total_amount)
      }

      return ''
    }
  },
  async mounted () {
    try {
      const response = await this.$axios.get('/payment-method/OfflinePayment')
      this.paymentNote = this.$store.state.language === 'en' ? response.data.note : response.data.note_cn
      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}/payment-info`)
      if (res.data) {
        this.reservation = res.data
        if (this.reservation.payment_method === 'NotSelected') {
          this.goPayment()
        }

        setInterval(() => {
          const time = dayjs(this.reservation.last_pay_time).diff(dayjs())
          if (time >= 0) {
            this.day = Math.floor(time / 3600000 / 24)
            this.hour = Math.floor((time % (3600000 * 24)) / 3600000)
            this.minute = Math.floor((time % 3600000) / 60000)
            this.second = Math.floor(time % 60000 / 1000)
          } else {
            this.day = '-'
            this.hour = '-'
            this.minute = '-'
            this.second = '-'
          }
        }, 200)
        if (res.data.image) {
          this.fileList = [
            {
              url: res.data.image,
              deletable: this.reservation.payment_status === 'Unpaid',
              imageFit: 'contain'
            }
          ]
        }
      }
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    formatNumber,
    compressImg (file, options) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
          ...options,
          success (result) {
            resolve(result)
          },
          error (err) {
            reject(err)
          }
        })
      })
    },
    async afterRead (optons) {
      optons.status = 'uploading'
      const { file } = optons
      let result
      if (file.size > 1024 * 2000) { // 大于2MB, 自动压缩
        try {
          result = await this.compressImg(file, {
            convertSize: Infinity,
            maxWidth: 2048,
            maxHeight: 2048,
            quality: 0.6
          })
        } catch (e) {
          Notify(e.message)
        }
      }
      const formData = new FormData()
      formData.append('file', result || file, result ? result.name : file.name)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      try {
        const res = await this.$axios.post('/file/upload', formData, config)
        if (res && res.errCode === 0) {
          optons.status = 'done'
          optons.file_name = res.file_name
          optons.name = res.name
        }
      } catch (e) {
        console.error(e)
        optons.status = 'failed'
      }
    },
    onOversize (file) {
      Notify(this.$t('file size limit is {limit} MB', { limit: 5 }))
    },
    goPayment () {
      this.$router.push({
        name: 'Payment',
        params: { reservationId: this.reservation.id }
      })
    },
    async submit () {
      if (this.fileList.length > 0) {
        try {
          if (!this.reservation.remark) {
            throw new Error(this.$t('paymentRemark'))
          }

          if (this.reservation.payment_method !== 'OfflinePayment') {
            this.goPayment()
          }

          await this.$axios.put(`/reservation/${this.$route.params.reservationId}/payment-info`, {
            reservation_id: this.$route.params.reservationId,
            payment_method: 'OfflinePayment',
            file_name: this.fileList[0].file_name,
            remark: this.reservation.remark
          })

          const _this = this
          this.$dialog.confirm({
            message: this.$t('do you need invoice'),
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()
                _this.$router.push({
                  name: 'Invoice',
                  params: { reservationId: _this.$route.params.reservationId }
                })
              } else {
                done()
              }
            }
          })
            .catch(() => {
              _this.$router.push({
                name: 'BookingSuccess'
              })
            })
        } catch (e) {
          Notify(e.message)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.van-row {
  color: #333333;

  .van-col {
    padding: 10px;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.reservation {
  padding: 1rem 1rem 0 1rem;
}

.note {
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
  line-height: 1.5em;
}

.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}

.clock-number {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

</style>
